<template>
  <div class="footer-wrapper">
    <!-- TODO -->
    <ul class="label-box">
      <li @click="gotoAbout(1)">关于我们</li>
      <li @click="goServe">服务协议</li>
      <li @click="goPrivacy">隐私政策</li>
    </ul>
    <div class="text-box">
      Copyright © 2023 上海萌泰数据科技股份有限公司
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        data-v-f67e13d4=""
        style="font-size: 12px"
      >
        沪ICP备09099644号-17
      </a>
      <a
        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002004960"
        target="_blank"
        data-v-f67e13d4=""
        style="font-size: 12px"
      >
        沪公网安备 31011002004960号
      </a>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const gotoAbout = (key) => {
  if (key == 1) {
    window.open("http://www.monetware.com/page/introduction.html");
  } else {
    router.push({
      path: "/privacy",
      query: {
        key: key,
      },
    });
  }
};
const goServe = () => {
  window.open("https://www.ringdata.com/user_agreement");
};

const goRelate = () => {
  window.open("http://www.monetware.com/page/introduction.html");
};
const goPrivacy = () => {
  window.open("https://www.ringdata.com/privacy_policy");
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  padding: 20px;
  text-align: center;
  height: 100%;
  .label-box {
    margin-bottom: 10px;
    li {
      display: inline-block;
      margin-right: 20px;
      @include pointer();
    }
  }
  .text-box {
    font-size: 12px;
    a {
      color: #00405e;
      margin-left: 5px;
    }
  }
}
</style>
