<template>
  <div style="width: 100%">
    <!-- 头部 -->
    <IndexHeader></IndexHeader>

    <!-- 内容 -->
    <div class="nav nav-media">
      <div class="section-case icon-banner">
        <div class="section-header">
          <h1 class="title">他们正在使用云统计</h1>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case01.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">国家卫计委</p>
                    <p class="case-name">中国家庭发展追踪调查</p>
                  </div>
                </div>
                <div class="case-desc">
                  国家卫计委在全国组织开展的“中国家庭发展追踪调查”项目，在全国31个省（区、市）中随机抽取1500个村（居），3000多户家庭。
                  调查内容涉及家庭及其成员的婚配状况、收入消费、住房、医疗、健康水平、育儿养老等方面。本次调查采用入户面访，问卷调查的方
                  式进行，共设计了住户、个人、村/居3大类共计7份问卷。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case02.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">上海统计局</p>
                    <p class="case-name">统计调查数据平台建设</p>
                  </div>
                </div>
                <div class="case-desc">
                  上海市统计局统计调查数据平台建设项目是集数据采集、处理、统计、分析和发布于一体的统一数据管理平台，涵盖了萌泰锐研小数据系列的所有产品包括电访、面访、网访、抽样等。该项目的成功实施有助于统计局完成统一数据平台的建设，从而充分发挥数据的协同效应，实现平台共享，数据共享和价值共享。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case03.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">贵州大学</p>
                    <p class="case-name">喀斯特地区经济发展调查（KEDS）</p>
                  </div>
                </div>
                <div class="case-desc">
                  贵州大学喀斯特地区经济发展调查是全国第一个以喀斯特地区为中心的经济发展调查，该调查项目具有地域性、贫困性、可利用性以及战略性等特点，能够反映喀斯特地区经济、人口、教育、健康等多维度的变化，为学术研究及人才培养提供数据支持，为国内和国际学术界提供研究资源，促进喀斯特地区的经济社会发展，可谓百千万的数据库工程。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case04.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">斯坦福大学、香港科技大学、武汉大学</p>
                    <p class="case-name">中国企业—员工匹配调查（CEES）</p>
                  </div>
                </div>
                <div class="case-desc">
                  中国企业——员工匹配调查（CEES）采用科学的抽样方法，对全国抽中的企业进行入企调查，并定期对参与调查的企业进行电话回访，以建立时间具有连续性、地域具有代表性的中国企业数据库。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case05.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">南京大学</p>
                    <p class="case-name">中国民众经济信心指数调查</p>
                  </div>
                </div>
                <div class="case-desc">
                  中国民众经济信心指数调查是由南京大学紫金传媒智库于2015年创立的一个常规调查项目。该调查采用传统的电话调查方法，并结合文本大数据研究方法，推出了《中国民众的经济信心指数报告》、《上市公司公开数据中的经济信心指数解读》、《新闻媒体大数据解读中国经济信心》等研究成果。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case06.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">上海财经大学</p>
                    <p class="case-name">千村调查</p>
                  </div>
                </div>
                <div class="case-desc">
                  千村调查是山海财经大学实施“211工程”的创新人才培养项目，该项目自2008年开始每年组织学生“走访千村，访万户，读中国”，探索出了一条融国情教育、科研训练、创新实践三位一体的人才培养路径。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="case-grids">
          <el-row>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case07.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">东北林业大学</p>
                    <p class="case-name">林业经济调查</p>
                  </div>
                </div>
                <div class="case-desc">
                  该项目有国家林业局组织，以国家天然林资源保护、退耕还林、京津风沙源及保护区建设四大工程为对象，连续十年对全国27个省（自治区、直辖市）的171个县、37个森工企业、40个保护区79个林场278个村和1656和农户（职工）的监测。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case08.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">华东师范大学</p>
                    <p class="case-name">社会调查、社会工作与大数据建设</p>
                  </div>
                </div>
                <div class="case-desc">
                  华东师范大学社会调查域社会工作实训中心目前是国内社会科学领域功能最为齐备的实验室之一，该实验室由萌泰科技全程设计和建设，下设社会调查中心、社会工作实训中心和多媒体采编中心，可支持社会学、社会工作、人口学、人类学和民俗学多项学科的教学与研究。
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="cardbox">
                <div class="case-card">
                  <div class="case-img">
                    <img src="../../assets/imgs/index/case09.png" />
                  </div>
                  <div class="case-title">
                    <p class="org-name">TNS KantarHealth</p>
                    <p class="case-name">医疗健康调查</p>
                  </div>
                </div>
                <div class="case-desc">
                  KantarHealth是全球最大的市场研究企业TNS旗下医药和生物技术咨询机构，拥有深入、广泛的全球流行病学信息，在复杂的肿瘤药物市场拥有无与伦比的经验。
                  KantarHealth在国内开展了广泛的医学研究，萌泰数据通过成熟的抽样问卷调查技术帮助客户更好地收集、处理和分析数据。
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <BaseFooter></BaseFooter>
  </div>
</template>

<script>
import IndexHeader from "@/components/layout/IndexHeader.vue";
import BaseFooter from "@/components/layout/BaseFooter.vue";
export default {
  components: {
    IndexHeader,
    BaseFooter,
  },
  data() {
    return {
      isActive: 2,
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
  },
  methods: {
    // 切换导航
    changeActive(current) {
      if (current != 2) {
        this.destroyed();
      }
      this.isActive = current;
    },
    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },
  },
};
</script>

<style lang="scss">
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
@import "../../assets/styles/media.scss";
* {
  font-weight: 300;
  color: #333;
}
p {
  // margin-top: 0 !important;
}
#user {
  line-height: 0;
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  i {
    font-weight: 500;
    margin-left: 3px;
    vertical-align: baseline;
  }
}
.bug {
  cursor: pointer;
  width: 35px;
  height: 60px;
  float: right;
  padding-right: 20px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  a {
    cursor: pointer;
    width: 35px;
    height: 60px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
  }
}
.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #760478;
      }
      &.active {
        color: #760478;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}
.container {
  max-width: 1200px !important;
}
.nav {
  margin-top: 60px;
  padding: 0 50px;
  .section-header h1.title {
    background: #fff;
    text-align: center;
    color: #000;
    font-size: 34px;
    font-weight: 300;
    margin-bottom: 30px;
  }
  .section-header p.desc {
    text-align: center;
    color: grey;
    font-size: 16px;
    font-weight: 300;
  }
  .section-case {
    padding: 50px 0px 10px;
    .section-header {
      margin-bottom: 50px;
    }
    .case-grids {
      overflow: hidden;
    }
    .cardbox {
      width: 100%;
      margin-bottom: 40px;
    }
    .case-card {
      padding: 10px;
      margin: 0 20px;
      border: 1px solid #fff;
      -webkit-box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      -moz-box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      box-shadow: 0 5px 18px 0 rgba(82, 94, 102, 0.15);
      &:hover {
        border-color: #f27800;
      }
    }
    .case-img img {
      width: 100%;
      height: 168px;
    }
    .case-title .org-name {
      font-size: 18px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .case-title .case-name {
      font-size: 20px;
      margin-bottom: 0px;
    }
    .case-desc {
      text-indent: 30px;
      text-align: justify;
      margin-top: 20px;
      padding: 0 20px;
      line-height: 23px;
    }
  }
}
.mr {
  margin-right: 0 !important;
}

// 头部
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  .navbar-header {
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 10px 0px;
    img {
      display: inline-block;
      width: 220px;
      height: 55px;
      vertical-align: middle;
    }
  }
  .header-right {
    float: right;
    text-align: right;
    ul {
      width: 100%;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      li {
        margin-right: 20px;
        float: left;
        cursor: pointer;
        a {
          &.active {
            color: #760478;
          }
        }
        .register {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-left: 20px;
        }
        i {
          color: #606266;
        }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          margin-left: 2px;
          display: inline-block;
          padding: 0;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
      }
    }
  }
}

// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
    img {
      margin-top: 10px;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #760478;
    }
  }
}
</style>
