<!-- 首页头部 -->
<template>
  <!-- { 'header-bg': isCeiling } -->
  <div :class="['header-wrapper', 'header-bg']">
    <div class="header-content">
      <div class="logo">
        <router-link to="/">
          <img src="../../assets/imgs/logo.png" alt="" />
        </router-link>
      </div>

      <div class="navs">
        <ul class="nav hidden-xs-only">
          <li :class="{ active: $route.name == 'Index' }">
            <router-link to="/">产品</router-link>
          </li>
          <li :class="{ active: $route.name == 'Case' }">
            <router-link to="/case">案例</router-link>
          </li>
          <li :class="{ active: $route.name == 'Pricing' }">
            <router-link to="/pricing">咨询与购买</router-link>
          </li>
        </ul>
        <div class="btns hidden-xs-only">
          <div v-if="isLogin">
            <router-link
              class="btns-item"
              to="/project"
              style="margin-right: 20px"
            >
              进入平台
              <el-icon><Right /></el-icon>
            </router-link>
            <a href="">
              <i class="iconfont icon-user" style="margin-right: 5px"></i>
              {{ userName }}
            </a>
          </div>
          <div v-else>
            <el-button class="btns-register" @click="handleClickGoRegister">
              注册
            </el-button>
            <el-button
              type="primary"
              class="btns-login"
              @click="handleClickGoLogin"
            >
              登录
            </el-button>
          </div>
        </div>
        <i
          @click="isCollapse = !isCollapse"
          class="el-icon-menu hidden-sm-and-up"
          style="font-size: 26px"
        ></i>
      </div>
    </div>
    <transition name="collapse">
      <div v-show="isCollapse" class="header-collapse">
        <ul>
          <li>
            <router-link to="/">产品</router-link>
          </li>
          <li>
            <router-link to="/case">案例</router-link>
          </li>
          <li>
            <router-link to="/pricing">咨询与购买</router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { useUserInfo } from "@/utils/useUserInfo";
import { httpPost } from "@/api/httpService.js";
import { Right } from "@element-plus/icons-vue";
import { goLogin, goRegister } from "@/utils/login";
import VueCookies from "vue-cookies";

export default {
  data() {
    return {
      isLogin: false,
      userName: null,
      isCeiling: false,
      isCollapse: false,
    };
  },
  created() {
    const userInfo = useUserInfo();
    const { name } = userInfo;

    // console.log("gog");
    /*判断用户是否登陆*/
    if (name) {
      // let userInfo = this.$cookies.get("userInfo");
      let userInfo = useUserInfo();
      this.userName = userInfo.name;
      if (userInfo && !window.sessionStorage.getItem("ifLoginSTAT")) {
        httpPost("/user/v1/permission", null).then((res) => {
          if (res && res.code === 0) {
            userInfo.surveyPermission = res.data;
            // userInfo.ifLoginSTAT = true;
            this.$cookies.set("userInfo", JSON.stringify(userInfo));
          }
        });
      }
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    let clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth >= 767) {
      window.addEventListener("scroll", this.handleScroll, false);
    }
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 0) {
        this.isCeiling = true;
      } else {
        this.isCeiling = false;
      }
    },
    //注册
    handleClickGoRegister() {
      // setTimeout(() => {
      // router.push("/login");
      goRegister();
      // }, 500);
    },
    //登录
    handleClickGoLogin() {
      // setTimeout(() => {
      // router.push("/login");
      goLogin();
      // }, 500);
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
}
.menu-icon {
  color: #777777;
  font-size: 28px !important;
  cursor: pointer;
}
.header-collapse {
  background: #ffffff;
  text-align: center;
  border-top: 1px solid #cccccc;
  box-shadow: 0px 7px 7px -7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  ul {
    padding: 10px 0;
  }
  li {
    padding: 15px 0;
    a {
      color: #777777;
    }
  }
}
// .collapse-enter-active, .collapse-leave-active {
//   transition: all .5s ease;
// }
// .collapse-enter, .collapse-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   height: 0;
// }
</style>

<style lang="scss">
.header-content {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .logo {
    // width: 230px;
    height: 40px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .navs {
    display: flex;
    align-items: center;
    padding-right: 20px;
    a {
      color: #000;
    }
    .nav {
      margin-right: 30px;
      li {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
        &.active {
          a {
            color: #f27800 !important;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .btns {
      .btns-item {
        display: inline-flex;
        align-items: center;
      }
      .btns-register {
        padding-right: 15px;
      }
    }
  }
}
.header-bg {
  background: #ffffff;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
  transition: all 0.3s;
  a {
    color: #777777 !important;
  }
}
</style>
